import React from "react";
import Newsletter from "../../../components/newsletter";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";

const NewsletterArea = () => {
    return (
        <div className="news-letter-section section-pb text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            subTitle=""
                            title="Your knowledge can change 
                            <span class='text-primary'>the world.</span>
                            "
                        />
                    </div>
                    <div className="col-xl-8 col-lg-10 mx-auto">
                        <Button
                            classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                            text="JOIN FOR FREE"
                            path="https://app.lermo.io"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterArea;
