import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-9"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                />
                                <p>
                                    Techster Co.,Ltd
                                    <br />
                                    Email: contact@techster.in.th
                                    {/* <br/> */}
                                    {/* Address: 39 Chaopraya Jewelry building, 4th floor, Phayathai Rd., Ratchathewi district, Bangkok 10400 THAILAND */}
                                </p>
                                <ul className="footer-social-links">
                                    {/* <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/lermo.io"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.youtube.com/channel/UCZ508VRGlFVQvatvx3TxCYw"
                                            icon="icofont-youtube-play"
                                        />
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-sm-6 mb-7 offset-xl-1">
                            <div className="footer-widget">
                            </div>
                        </div> */}
                        <div className="offset-lg-6 col-lg-2 offset-sm-3 col-sm-3 mb-2">
                            <div className="footer-widget">
                                <h4 className="title"></h4>
                                <div className="single-brand">
                                    <img src="/images/brand-logo/edstart-badge.png" alt="brand logo" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">Contact info</h4>
                                <p>
                                    245 Southern Street, Apt. 147 New York, USA
                                </p>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+12354569874"
                                        >
                                            +12354 569 874
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+98745612398"
                                        >
                                            +98745 612 398
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@example.com"
                                        >
                                            info@example.com
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@example.com"
                                        >
                                            www.example.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Copyright &copy; 2023 Techster Co.,Ltd
                                    , All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
